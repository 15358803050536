import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  IconButton
} from '@material-ui/core';
import clsx from 'clsx';
import { ArrowForwardIos, Eco } from '@material-ui/icons';
import { useDecision } from '@optimizely/react-sdk';
import { BundleChoiceVariant } from '@pizza-hut-us-development/client-core';
import Badge, { SoldOutBadge } from '../../coreComponents/boxes/Badge';
import styles from './MenuTile.styles';
import ResponsiveImageRender from '../../common/ResponsiveImageRender';
import { formatTestId } from '@/common/string-formatter';
import InfoIcon from '../../localization/icons/InfoIcon';
import InfoRail from '../../common/components/InfoRail';
import InfoRailStyles from '../../common/components/InfoRail/InfoRail.styles';
import telemetry from '@/telemetry';
import PriceDisplay from '@/common/PriceDisplay/PriceDisplay';
import ProductImage from '@/common/components/LocalizedProductTile/ProductImage';
import useProductTileClasses from '@/common/components/LocalizedProductTile/styles';
import ProductPrice from '@/common/PriceDisplay/ProductPrice';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import decodeEntities from '@/clientCore/helper/decodeEntities';
import useDealPricingPrefix from '@/menu/deals/useDealPricingPrefix';
import useShowNewBadge from '../hooks/useShowNewBadge';
import Routes from '@/router/routes';
import NewBadge from '@/coreComponents/boxes/Badge/NewBadge';
import { SodiumWarningIcon } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningIcon';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';

const useStyles = makeStyles({
  cta: {
    paddingRight: '24px',
    marginLeft: 'auto'
  },
  badges: {
    display: 'flex',
    gap: '5px'
  },
  imageClickable: {
    cursor: 'pointer'
  }
});

export interface MenuTileProps {
  handleClick: () => void;
  name: string;
  title?: string;
  imageURL: string;
  hasLinkEnabled: boolean;
  productDescription?: string;
  staticLink?: string;
  price?: number;
  displayPrice?: string;
  badges?: {
    popular?: boolean;
    soldOut?: boolean;
    vegetarian?: boolean;
  };
  testId?: string;
  hideDescription?: boolean;
  showPricePrefix?: boolean;
  allergenDisclaimer?: string;
  handleInfoClick?: () => void;
  handleInfoDrawerLoaded?: () => void;
  description?: string;
  currentRoute?: string;
  productId?: string;
  onGetStartedLinkClick?: () => void;
  upcharges?: BundleChoiceVariant[];
  sodiumWarning?: boolean;
}

const MenuTile = (props: MenuTileProps): JSX.Element => {
  const {
    handleClick,
    testId,
    staticLink,
    name,
    title,
    hasLinkEnabled,
    imageURL,
    productDescription: dealsDescription,
    price,
    displayPrice,
    badges = {},
    hideDescription,
    showPricePrefix = true,
    allergenDisclaimer,
    handleInfoClick: parentHandleInfoClick,
    handleInfoDrawerLoaded,
    description: pizzaDescription,
    currentRoute,
    productId,
    onGetStartedLinkClick,
    upcharges = [],
    sodiumWarning = false
  } = props;
  const { popular, soldOut, vegetarian } = badges;
  const classes = styles({ outOfStock: soldOut, hasLinkEnabled });
  const classesInfoRail = InfoRailStyles();
  const [showInfoRail, setShowInfoRail] = useState<boolean>(false);

  const [{ enabled: isMyHutBoxEnabled }] = useDecision('cb-my_hut_box', { autoUpdate: true });
  const [{ enabled: isTavernEnabled }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });
  const isCYO = name.toLowerCase().includes('create');

  const showDealPricing = useDealPricingPrefix(name);
  const { shouldShowNewBadge } = useShowNewBadge(productId);
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  const showSodiumWarning = isShowSodiumAlertForStore && sodiumWarning;

  useEffect(() => {
    if (showInfoRail && handleInfoDrawerLoaded) {
      handleInfoDrawerLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInfoRail]);

  const handleTileClick = (e?: React.SyntheticEvent) => {
    if (staticLink) e?.preventDefault();
    if (!hasLinkEnabled) return;
    handleClick();
  };

  const handleCTAClick = () => {
    onGetStartedLinkClick?.();
    handleTileClick();
  };

  const handleInfoClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (parentHandleInfoClick) parentHandleInfoClick();
    telemetry.addCustomEvent(`${formatTestId(name)}-info-button-click`);
    setShowInfoRail(true);
  };

  const description = dealsDescription || pizzaDescription;
  const showDisplayInfoButton = description && hasLinkEnabled;
  const infoRail = showDisplayInfoButton ? (
    <InfoRail visible={showInfoRail} onClose={() => setShowInfoRail(false)}>
      <Box p={2}>
        <Typography component="p" className={classesInfoRail.subtitle}>
          {name}
        </Typography>
        <Typography className={classesInfoRail.description}>
          {description}
        </Typography>
      </Box>
    </InfoRail>
  ) : null;

  const menuTileClasses = useStyles();
  const productTileClasses = useProductTileClasses();
  const tileClassName = clsx(
    productTileClasses.grid,
    productTileClasses.gridTemplateWhenNoDropdown
  );

  if (isTavernEnabled && currentRoute === Routes.MENU.PIZZA) {
    let badgeComponent = null;
    if (shouldShowNewBadge) {
      badgeComponent = <NewBadge />;
    } else if (popular) {
      badgeComponent = <Badge alert text="POPULAR" />;
    } else if (soldOut) {
      badgeComponent = <SoldOutBadge />;
    }

    return (
      <Grid item className={tileClassName}>
        <ProductImage
          imageUrl={imageURL}
          description={description ?? ''}
          onClick={handleTileClick}
          classes={{
            image: clsx(hasLinkEnabled && menuTileClasses.imageClickable)
          }}
        />
        <div className={productTileClasses.priceAndNameContainer}>
          <Grid className={clsx(productTileClasses.productInfoContainer)}>
            <div>
              <div data-testid="tile-badge" className={menuTileClasses.badges}>
                {badgeComponent}
              </div>
              <Typography className={clsx(soldOut && classes.outOfStock, classes.title)}>
                {name}
              </Typography>
              {!!price && (
                <ProductPrice
                  price={price}
                  displayPrice={displayPrice}
                  name={name}
                  productId={productId}
                  title={title}
                  showPrefix
                />
              )}
            </div>
            {description && (
              <IconButton
                aria-label="Product Info"
                aria-haspopup="dialog"
                disableFocusRipple
                disableRipple
                className={productTileClasses.infoIcon}
                onClick={handleInfoClick}
              >
                <InfoIcon />
              </IconButton>
            )}
          </Grid>
        </div>
        <div className={productTileClasses.sizeDropdownAndCtaButtonWrapper}>
          <div className={menuTileClasses.cta}>
            <CtaButton
              color={isCYO ? 'primary' : 'secondary'}
              labelText="Get Started"
              size="medium"
              disabled={!hasLinkEnabled}
              tabIndex={-1}
              ariaHidden
              clickHandler={handleCTAClick}
            />
          </div>
        </div>
        {infoRail}
      </Grid>
    );
  }

  switch (currentRoute) {
    case '/deals':
      return (
        <Card className={classes.card}>
          {infoRail}
          <CardActionArea
            className={classes.cardActionArea}
            aria-disabled={!!soldOut}
            tabIndex={hasLinkEnabled ? 0 : -1}
            onClick={handleTileClick}
            data-testid={testId}
            disableRipple
            {...(staticLink
              ? {
                component: 'a',
                href: staticLink
              }
              : {})}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                <CardContent
                  className={`${classes.content} ${classes.paddingRightZero}`}
                >
                  <Grid container item className={classes.contentGrid}>
                    <ResponsiveImageRender
                      title=""
                      testId={name || ''}
                      className={classes.image}
                      mobile={
                        imageURL
                        || 'images/nationalMenu/fallback-product-image-mobile.jpg'
                      }
                      desktop={
                        imageURL
                        || '/images/nationalMenu/fallback-product-image-desktop.jpg'
                      }
                      altText=""
                      ariaHidden
                    />
                    <Grid item className={classes.dealMenuItemContent}>
                      <Grid item className={classes.textContent}>
                        <div className={classes.flexBetween}>
                          <div>
                            {shouldShowNewBadge && <NewBadge />}
                            <Typography
                              data-testid="deal-product-name"
                              className={`${soldOut ? classes.outOfStock : ''} ${classes.title} ${classes.dealMenuItemTitle}`}
                            >
                              {decodeEntities(name)}
                            </Typography>
                          </div>
                          <div className={classes.dealMenuIconContainer}>
                            {showDisplayInfoButton && (
                              <IconButton
                                aria-label="Item Details"
                                disableFocusRipple
                                disableRipple
                                onClick={handleInfoClick}
                                component="span"
                              >
                                <InfoIcon />
                              </IconButton>
                            )}
                          </div>
                        </div>
                        {soldOut ? (
                          <Typography
                            component="span"
                            className={classes.outOfStockTitle}
                          >
                            <SoldOutBadge />
                          </Typography>
                        ) : (
                          <>
                            {dealsDescription && !hideDescription && (
                              <Typography className={classes.description}>
                                {dealsDescription}
                              </Typography>
                            )}
                            {isMyHutBoxEnabled && price
                              ? (
                                <ProductPrice
                                  upcharges={upcharges}
                                  price={price}
                                  displayPrice={displayPrice}
                                  name={name}
                                  productId={productId}
                                  showPrefix={showDealPricing}
                                  isDeal
                                />
                              )
                              : (
                                <PriceDisplay
                                  upcharges={upcharges}
                                  price={price}
                                  name={name}
                                  productId={productId}
                                  displayPrice={displayPrice}
                                  showPrefix={showDealPricing}
                                  isDeal
                                />
                              )}
                          </>
                        )}
                        {showSodiumWarning && <SodiumWarningIcon width="116" height="14" />}
                      </Grid>
                      <div className={classes.flexEnd}>
                        {hasLinkEnabled && (
                          <CtaButton
                            color="secondary"
                            labelText="Get Started"
                            size="medium"
                            tabIndex={-1}
                            ariaHidden
                            buttonBaseTag="a"
                          />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </CardActionArea>
        </Card>
      );
    default:
      return (
        <Card className={classes.card}>
          {infoRail}
          <CardActionArea
            className={classes.cardActionArea}
            aria-disabled={!!soldOut}
            tabIndex={hasLinkEnabled ? 0 : -1}
            onClick={handleTileClick}
            data-testid={testId}
            disableRipple
            {...(staticLink
              ? {
                component: 'a',
                href: staticLink
              }
              : {})}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth>
                <CardContent className={classes.content}>
                  <Grid container item className={classes.contentGrid}>
                    <ResponsiveImageRender
                      title=""
                      testId={name || ''}
                      className={classes.image}
                      mobile={
                        imageURL
                        || 'images/nationalMenu/fallback-product-image-mobile.jpg'
                      }
                      desktop={
                        imageURL
                        || '/images/nationalMenu/fallback-product-image-desktop.jpg'
                      }
                      altText=""
                      ariaHidden
                    />
                    <Grid item className={classes.textContent}>
                      {popular && (
                        <div className={classes.popularTitle}>
                          <Badge alert text="POPULAR" />
                        </div>
                      )}
                      <Typography
                        className={`${soldOut ? classes.outOfStock : ''} ${classes.title}`}
                      >
                        {name}
                      </Typography>
                      {soldOut ? (
                        <Typography
                          component="span"
                          className={classes.outOfStockTitle}
                        >
                          <SoldOutBadge />
                        </Typography>
                      ) : (
                        <>
                          {dealsDescription && !hideDescription && (
                            <Typography className={classes.description}>
                              {dealsDescription}
                            </Typography>
                          )}
                          {isMyHutBoxEnabled && price
                            ? (
                              <ProductPrice
                                price={price}
                                showPrefix={showPricePrefix}
                                name={name}
                                productId={productId}
                                title={title}
                              />
                            )
                            : (
                              <PriceDisplay
                                price={price}
                                showPrefix={showPricePrefix}
                                name={name}
                                productId={productId}
                              />
                            )}
                          {allergenDisclaimer && (
                            <Typography
                              component="p"
                              className={classes.allergenDisclaimer}
                            >
                              {allergenDisclaimer}
                            </Typography>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
              <div className={classes.iconContainer}>
                <div className={classes.flexCenter}>
                  {vegetarian && (
                    <Eco
                      className={classes.vegetarianIcon}
                      aria-hidden="false"
                      aria-label="vegetarian"
                    />
                  )}
                  {showDisplayInfoButton && (
                    <IconButton
                      aria-label="Item Details"
                      disableFocusRipple
                      disableRipple
                      onClick={handleInfoClick}
                      component="span"
                    >
                      <InfoIcon />
                    </IconButton>
                  )}
                </div>
                {hasLinkEnabled && (
                  <ArrowForwardIos
                    className={classes.caratIcon}
                    data-testid={name && formatTestId(`arrow ${name}`)}
                  />
                )}
              </div>
            </Grid>
          </CardActionArea>
        </Card>
      );
  }
};

export default MenuTile;
