import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import { BundleChoiceVariant } from '@pizza-hut-us-development/client-core';
import formattedPrice from '../formattedPrice';
import { HOT_HONEY_PARTIAL, TAVERN_PIZZA } from '@/builders/pizza/constants';
import useHidePriceHardcoding from '../../../hardcoding/utils/useHidePriceHardcoding';
import { RootState } from '@/rootStateTypes';
import { productCheck } from '@/common/PriceDisplay/ProductPrice/hooks/useProductPrice';
import useDealTilePriceHardcoding from '../../../hardcoding/utils/useDealTilePriceHardcoding';

export interface PriceDisplayProps {
  price?: number;
  displayPrice?: string;
  showPrefix?: boolean;
  name?: string;
  isDeal?: boolean;
  productId?: string;
  upcharges?: BundleChoiceVariant[];
}

const PriceDisplay = ({
  price, displayPrice, showPrefix = true, name, productId, isDeal, upcharges = []
}: PriceDisplayProps): JSX.Element => {
  const { shouldHidePrice } = useHidePriceHardcoding(name, productId, isDeal);
  const [{ enabled: showPricingPrefixForDeals }] = useDecision('fr-dtg-1004-deals-pricing-prefix-temp');
  const [{ enabled: showLargePricingPointForTavernDeal }] = useDecision('fr-dtg-1000-tavern-large-price-point');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const pricingPrefixforNonYumDeals = showPricingPrefixForDeals && !isYumEcomm;
  const { dealPrice: updatedDealPrice } = useDealTilePriceHardcoding(productCheck(name!, TAVERN_PIZZA), price ?? 0, upcharges);

  if (shouldHidePrice) {
    return <></>;
  }

  if (typeof price !== 'number') return <></>;

  // WEB-2029 - do not show price prefix for Hot Honey pizza
  const isHotHoney = name?.includes(HOT_HONEY_PARTIAL);

  const showTavernLargePrice = isYumEcomm && updatedDealPrice && showLargePricingPointForTavernDeal;

  const showPricingPrefix = showPrefix && (!isHotHoney || pricingPrefixforNonYumDeals);

  let startingAtPrefix = 'Large s';
  if (name?.toLowerCase().includes('triple treat box') || name?.toLowerCase().includes('big dinner box')
    || pricingPrefixforNonYumDeals) {
    startingAtPrefix = 'S';
  } else if (name?.toLowerCase().includes('detroit')) {
    startingAtPrefix = 'Medium s';
  } else if (name?.toLowerCase().includes(TAVERN_PIZZA.toLowerCase())) {
    startingAtPrefix = 'Large Tavern recipes s';
  }

  return (
    <Typography data-testid="price-display">
      {showTavernLargePrice
        ? `Large Tavern Create-Your-Own starting at ${updatedDealPrice}`
        : `${showPricingPrefix ? `${startingAtPrefix}tarting at ` : ''}${displayPrice || formattedPrice(price)}`}
    </Typography>
  );
};

export default PriceDisplay;
