import React from 'react';
import Typography from '@material-ui/core/Typography';
import useProductPrice from './hooks/useProductPrice';
import { TProductPrice } from './types';

const ProductPrice = ({
  price,
  displayPrice,
  showPrefix = true,
  name,
  productId = '',
  title = '',
  isDeal = false,
  upcharges = []
}: TProductPrice): JSX.Element => {
  const productPrice = useProductPrice({
    price,
    displayPrice,
    showPrefix,
    productId,
    name,
    title,
    isDeal,
    upcharges
  });

  return (
    <Typography data-testid="product-price">
      {productPrice}
    </Typography>
  );
};

export default ProductPrice;
