import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import formattedPrice from '@/common/formattedPrice';

import { HOT_HONEY_PARTIAL, TAVERN_PIZZA } from '@/builders/pizza/constants';
import { TProductPrice } from '../types';
import useHidePriceHardcoding from '../../../../../hardcoding/utils/useHidePriceHardcoding';
import { RootState } from '@/rootStateTypes';
import { useDealTilePriceHardcoding } from '../../../../../hardcoding/utils/useDealTilePriceHardcoding';

export const productCheck = (productDescription: string, productKeywords: string): boolean => productDescription?.toLowerCase().includes(productKeywords.toLowerCase());

const useProductPrice = ({
  price, displayPrice, showPrefix, name, title, productId, isDeal, upcharges = []
}: TProductPrice) => {
  const { shouldHidePrice, isDealTile } = useHidePriceHardcoding(name, productId, isDeal);
  const [{ enabled: showSpecificPriceTextForYumEcomm }] = useDecision('ops-dtg981_show-yum-ecomm-price-text');
  const [{ enabled: showPricingPrefixForDeals }] = useDecision('fr-dtg-1004-deals-pricing-prefix-temp');
  const [{ enabled: showLargePricingPointForTavernDeal }] = useDecision('fr-dtg-1000-tavern-large-price-point');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const shouldShowStartingAtText = isYumEcomm && showSpecificPriceTextForYumEcomm && isDealTile && isDeal;

  const pricingPrefixforNonYumDeals = showPricingPrefixForDeals && !isYumEcomm;

  const { dealPrice: updatedDealPrice } = useDealTilePriceHardcoding(productCheck(name!, TAVERN_PIZZA), price, upcharges);

  if (shouldHidePrice) return '';

  // Hot Honey pizza
  const isHotHoney = productCheck(name!, HOT_HONEY_PARTIAL);

  // My Hut Box && Personal Pan Pizza
  const isMyHutBoxDeal = productCheck(title!, 'my hut box');
  const isPersonalPanPizza = productCheck(name!, 'personal pan pizza');
  const isMHBPersonalPanPizza = (isMyHutBoxDeal && isPersonalPanPizza);

  // Triple Treat Box
  const isTripleTreatBox = productCheck(name!, 'triple treat box');

  // Big Dinner Box
  const isBigDinnerBox = productCheck(name!, 'big dinner box');

  // Detroit Pizza
  const isDetroitPizza = productCheck(name!, 'detroit');

  const isTavernDeal = productCheck(name!, TAVERN_PIZZA);

  const showTavernLargePrice = isYumEcomm && updatedDealPrice && showLargePricingPointForTavernDeal;

  // DTG-1000 Pricing prefix for Tavern deal
  if (showTavernLargePrice) {
    return `Large Tavern Create-Your-Own starting at ${updatedDealPrice}`;
  }

  // "Starting at <price>"        -> if product is a triple treat box, big dinner box OR PPP under My Hut Box
  //                              -> if yum ecomm - temporary (pricing transparency is coming)
  // "Medium starting at <price>" -> if product is detroit pizza
  // "Large starting at <price>"  -> default price copy prefix
  // "<price>"                    -> all products under My Hut Box except for PPP, hot honey OR if a product explicitly doesn't need a prefix
  let prefix: string;
  if (isTripleTreatBox || isBigDinnerBox || isMHBPersonalPanPizza
    || shouldShowStartingAtText || pricingPrefixforNonYumDeals) {
    prefix = 'Starting at';
  } else if (isDetroitPizza) {
    prefix = 'Medium starting at';
  } else if ((isMyHutBoxDeal && !isPersonalPanPizza) || isHotHoney || !showPrefix) {
    prefix = '';
  } else if (isTavernDeal) {
    prefix = 'Large Tavern recipes starting at';
  } else {
    prefix = 'Large starting at';
  }

  return `${prefix} ${displayPrice || formattedPrice(price)}`;
};

export default useProductPrice;
