import { BundleChoiceVariant } from '@pizza-hut-us-development/client-core';
import formattedPrice from '@/common/formattedPrice/formattedPrice';

interface UseDealTilePriceHardcoding {
  dealPrice: string;
}

export const useDealTilePriceHardcoding = (isTavernDeal: boolean, price: number, upcharges: BundleChoiceVariant[]): UseDealTilePriceHardcoding => {
  // DTG-1000 - Marketing team wants to advertise large pricing point for tavern deal
  const largeTavernCYORegex = /.*CYO.*SIZE060L.*/;

  if (!isTavernDeal) {
    return { dealPrice: '' };
  }

  const tavernLargeUpchargePrice = upcharges?.find((variant) => largeTavernCYORegex.test(variant.variantCode));

  const dealPrice = formattedPrice((tavernLargeUpchargePrice?.upcharge?.amount || 0) + price);

  return { dealPrice };
};

export default useDealTilePriceHardcoding;
